"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.resolveFullCreature = exports.CREATURES = exports.GUARD = exports.COMMONER = exports.GOBLIN = void 0;
const VETERAN = {
    creatureName: "Veteran",
    name: "Captain Darius",
    description: "Captain Darius is a grizzled war veteran, clad in worn splint armor and wielding a longsword with precision. His years on the battlefield have made him a formidable opponent.",
    memories: [],
    info: "Veterans are experienced soldiers who have honed their skills in numerous battles. They are typically found leading smaller units or as mercenaries-for-hire.",
    hp: 29,
    maxhp: 29,
    ac: 16,
    size: "medium",
    stats: {
        str: 16,
        dex: 13,
        con: 14,
        wis: 11,
        cha: 10,
    },
    skills: ["Athletics", "Perception"],
    senses: [],
    languages: ["Common"],
    cr: 3,
    actions: [
        {
            name: "Longsword",
            description: "The veteran swings his longsword at a nearby opponent.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [{ sides: 10 }],
        },
        {
            name: "Heavy Crossbow",
            description: "The veteran fires a bolt at a distant target.",
            range: "Far",
            usable: true,
            abilityCheck: {
                stat: ["dex"],
                dc: "AC",
            },
            damage: [{ sides: 10 }],
        },
    ],
    conditions: [],
};
const KNIGHT = {
    creatureName: "knight",
    name: "Ser Ronald",
    description: "A valiant warrior clad in gleaming plate armor, Ser Ronald is a protector of the weak and a defender of justice.",
    memories: [],
    info: "A noble warrior trained in both melee and ranged combat, knights serve as the backbone of many armies.",
    hp: 26,
    maxhp: 26,
    ac: 17,
    size: "medium",
    stats: {
        str: 16,
        dex: 11,
        con: 14,
        wis: 11,
        cha: 15,
    },
    skills: [],
    senses: [],
    languages: ["Common"],
    cr: 3,
    actions: [
        {
            name: "Greatsword",
            description: "A heavy melee attack with a two-handed greatsword.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
            },
            damage: [{ sides: 6 }, { sides: 6 }],
        },
        {
            name: "Heavy Crossbow",
            description: "A ranged attack with a heavy crossbow.",
            range: "Far",
            usable: true,
            abilityCheck: {
                stat: ["dex"],
            },
            damage: [{ sides: 10 }, { sides: 10 }],
        },
    ],
    conditions: [],
};
const GOBLIN_BOSS = {
    creatureName: "Goblin Boss",
    name: "Grik the Cunning",
    description: "Grik the Cunning, a notoriously sly goblin, reigns over his tribe with a mix of fear and cunning. Known for his strategic mind, Grik navigates challenges with a deft hand and a shrewd grin.",
    memories: [],
    info: "Goblins are small, vindictive humanoids often found in dark, cramped places. Though physically weak, they rely on their numbers and cunning to overpower foes. Goblin bosses rise to power through a mix of intelligence and strength, leading their kind with authority until overthrown.",
    hp: 10,
    maxhp: 10,
    ac: 16,
    size: "small",
    stats: {
        str: 10,
        dex: 14,
        con: 10,
        wis: 8,
        cha: 10,
    },
    skills: ["stealth"],
    senses: ["darkvision 60 ft."],
    languages: ["Common", "Goblin"],
    cr: 1,
    actions: [
        {
            name: "Scimitar",
            description: "Attacks with a scimitar, dealing slashing damage.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["dex"],
                dc: "AC",
            },
            damage: [{ sides: 6 }, 2], // Equivalent to 1d6 + 2
        },
        {
            name: "Javelin",
            description: "Throws or jabs with a javelin, dealing piercing damage.",
            range: "Near",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [{ sides: 6 }], // Equivalent to 1d6
        },
        {
            name: "Nimble Evasion",
            description: "Avoids an attack or moves quickly to hide, applying the evasive condition.",
            range: "Self",
            usable: true,
            selfConditions: [
                {
                    name: "Evasive",
                    description: "The creature has an advantage on agility checks for a short duration.",
                    timed: {
                        duration: 12,
                        timeLeft: 12,
                    },
                },
            ],
        },
        {
            name: "Redirect Attack",
            description: "Swaps places with another ally, causing an opponent's attack to target the ally instead.",
            range: "Close",
            usable: true,
            targetedConditions: [
                {
                    name: "Target Swap",
                    description: "The intended attack now targets another creature within range.",
                },
            ],
        },
    ],
    conditions: [],
};
exports.GOBLIN = {
    creatureName: "Goblin",
    name: "Zig",
    description: "A simple goblin bandit. He wants gold, food, and to survive.",
    info: `Goblins are small, black-hearted, selfish humanoids that lair in caves, abandoned mines, despoiled dungeons, and other dismal settings. Individually weak, goblins gather in large-sometimes overwhelming-numbers. They crave power and regularly abuse whatever authority they obtain. Goblins are lazy and undisciplined, making them poor servants, laborers, and guards. Motivated by greed and malice, goblins can't help but celebrate the few times they have the upper hand. They dance, caper with sheer joy when victory is theirs. Once their revels have ended, goblins delight in the torment of other creatures and embrace all manner of wickedness. Goblins are ruled by the strongest or smartest among them. A goblin boss might command a single lair, while a goblin king or queen (who is nothing more than a glorified goblin boss) rules hundreds of goblins, spread out among multiple lairs to ensure the tribe's survival. Goblin bosses are easily ousted, and many goblin tribes are taken over by hobgoblin warlords or bugbear chiefs.`,
    hp: 7,
    maxhp: 7,
    ac: 14,
    size: "small",
    stats: {
        str: -2,
        dex: 2,
        con: 0,
        cha: -2,
        wis: -2,
    },
    skills: ["stealth"],
    senses: ["darkvision"],
    languages: ["Common", "Goblin"],
    cr: 0.25,
    actions: [
        {
            name: "Scimitar Attack",
            description: "The goblin slashes with its scimitar",
            usable: true,
            range: "Near",
            abilityCheck: {
                stat: ["dex"],
            },
            damage: [{ sides: 6 }],
        },
        {
            name: "Shortbow Attack",
            description: "The goblin shoots an arrow from its shortbow from afar",
            usable: true,
            range: "Far",
            abilityCheck: {
                stat: ["dex"],
            },
            damage: [{ sides: 6 }],
        },
    ],
    conditions: [],
};
const ORC = {
    creatureName: "Orc",
    name: "Gulrath the Savage",
    description: "A towering figure with a stooped posture, tusk-like canines, and a bloodlust in their eyes, Gulrath thrives in the chaos of battle. They carry a massive greataxe, scarred by countless skirmishes.",
    memories: [],
    info: "Orcs are brutish and aggressive humanoids that thrive on conflict and conquest. They organize into tribes, often raiding and pillaging to sustain their nomadic lifestyles. Orcs respect strength above all and are known for their fierce loyalty to powerful leaders.",
    hp: 7,
    maxhp: 7,
    ac: 12,
    size: "medium",
    stats: {
        str: 16,
        dex: 12,
        con: 16,
        wis: 11,
        cha: 10,
    },
    skills: ["intimidation"],
    senses: ["darkvision 60 ft."],
    languages: ["Common", "Orc"],
    cr: 0.5,
    actions: [
        {
            name: "Greataxe",
            description: "The orc swings its greataxe with brutal force.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [{ sides: 12 }],
            twoHands: true,
        },
        {
            name: "Javelin",
            description: "The orc hurls a javelin with precision.",
            range: "Near",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [{ sides: 6 }],
        },
        {
            name: "Savage Charge",
            description: "The orc charges toward an enemy with reckless aggression. The target must succeed on a Strength saving throw or be knocked prone.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: 12,
            },
            targetedConditions: [
                {
                    name: "Prone",
                    description: "The target is knocked prone, falling to the ground and losing mobility.",
                    timed: {
                        duration: 10,
                        timeLeft: 10,
                    },
                },
            ],
        },
    ],
    conditions: [],
};
exports.COMMONER = {
    creatureName: "Commoner",
    name: "Abigail",
    description: "The daughter of a nearby tanner. She wants to find a husband, and is afraid of the dark woods.",
    info: "Commoners include peasants, serfs, slaves, servants, pilgrims, merchants, artisans, and hermits.",
    hp: 4,
    maxhp: 4,
    ac: 10,
    size: "medium",
    stats: {
        str: 0,
        dex: 0,
        con: 0,
        cha: 0,
        wis: 0,
    },
    skills: [],
    senses: [],
    languages: ["Common"],
    cr: 0,
    actions: [
        {
            name: "Unarmed Strike",
            description: "Brawling with their fists",
            usable: true,
            range: "Close",
            abilityCheck: {
                stat: ["str"],
            },
            damage: [1],
        },
    ],
    conditions: [],
};
exports.GUARD = {
    creatureName: "Guard",
    name: "Bob",
    description: "A vigilant and disciplined protector, standing ready to defend their post or charge.",
    memories: [],
    info: "A standard city guard equipped with a spear, chain shirt, and shield. They are commonly found patrolling urban areas or guarding important locations.",
    hp: 5,
    maxhp: 5,
    ac: 15,
    size: "medium",
    stats: {
        str: 13,
        dex: 12,
        con: 12,
        wis: 11,
        cha: 10,
    },
    skills: ["Perception"],
    senses: [],
    languages: ["Any one language (usually Common)"],
    cr: 0.125,
    actions: [
        {
            name: "Spear (One Handed)",
            description: "A quick thrust with a spear.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [{ sides: 6 }],
        },
        {
            name: "Spear (Two Handed)",
            description: "A powerful thrust with a spear using both hands.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [{ sides: 8 }],
            twoHands: true,
        },
    ],
    conditions: [],
};
const SKELETON = {
    creatureName: "skeleton",
    name: "Unnamed Skeleton",
    description: "A reanimated skeleton of a humanoid, animated by dark magic to serve as a mindless minion.",
    memories: [],
    info: "Skeletons are the animated remains of humanoids, often used as guards or soldiers by necromancers and other practitioners of dark arts.",
    hp: 6,
    maxhp: 6,
    ac: 12,
    size: "medium",
    stats: {
        str: 10,
        dex: 16,
        con: 15,
        wis: 8,
        cha: 5,
    },
    skills: [],
    senses: ["darkvision 60 ft."],
    languages: ["understands the languages it knew in life but can't speak"],
    cr: 0.25,
    actions: [
        {
            name: "Shortsword",
            description: "The skeleton makes a melee attack with a shortsword.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [
                {
                    sides: 6,
                },
            ],
        },
        {
            name: "Shortbow",
            description: "The skeleton makes a ranged attack with a shortbow.",
            range: "Far",
            usable: true,
            abilityCheck: {
                stat: ["dex"],
                dc: "AC",
            },
            damage: [
                {
                    sides: 6,
                },
            ],
        },
    ],
    conditions: [
        {
            name: "Exhaustion",
            description: "The skeleton is immune to exhaustion.",
        },
        {
            name: "Poisoned",
            description: "The skeleton is immune to the poisoned condition.",
        },
    ],
};
const MAGE = {
    creatureName: "Mage",
    name: "Arvandil the Arcane",
    description: "Arvandil is a wandering scholar of the arcane arts, known for his mastery of elemental spells and his vast knowledge of forgotten lore.",
    memories: [],
    info: "A spellcaster with a versatile array of spells and a knack for knowledge, adept at both offense and defense.",
    hp: 40,
    maxhp: 40,
    ac: 14,
    size: "medium",
    stats: {
        str: 9,
        dex: 14,
        con: 11,
        wis: 12,
        cha: 11,
    },
    skills: ["Arcana", "History"],
    senses: [],
    languages: ["Common", "Sylvan", "Infernal", "Deep Speech"],
    cr: 6,
    actions: [
        {
            name: "Arcane Burst",
            description: "A burst of magical energy strikes a target.",
            range: "Far",
            usable: true,
            abilityCheck: {
                stat: ["dex"],
                dc: "AC",
            },
            damage: [
                {
                    sides: 10,
                },
                {
                    sides: 10,
                },
                {
                    sides: 10,
                },
            ],
        },
        {
            name: "Cone of Cold",
            description: "A blast of cold air emanates from the mage, freezing enemies in its path.",
            range: "Far",
            usable: true,
            abilityCheck: {
                stat: ["con"],
                dc: 13,
            },
            damage: [
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
            ],
        },
        {
            name: "Fireball",
            description: "A bright streak of flame explodes, dealing fiery damage to all creatures in range.",
            range: "Far",
            usable: true,
            abilityCheck: {
                stat: ["dex"],
                dc: 12,
            },
            damage: [
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
                {
                    sides: 6,
                },
            ],
        },
        {
            name: "Fly",
            description: "The mage casts a spell, gaining the ability to fly for a brief period.",
            range: "Self",
            usable: true,
            selfConditions: [
                {
                    name: "Flying",
                    description: "The creature is able to fly.",
                    timed: {
                        duration: 600,
                        timeLeft: 600,
                    },
                },
            ],
        },
        {
            name: "Suggestion",
            description: "The mage suggests a course of action to a creature.",
            range: "Near",
            usable: true,
            abilityCheck: {
                stat: ["wis"],
                dc: 12,
            },
            targetedConditions: [
                {
                    name: "Charmed",
                    description: "The creature is charmed and follows the mage's suggestion.",
                    timed: {
                        duration: 180,
                        timeLeft: 180,
                    },
                },
            ],
        },
    ],
    conditions: [],
};
const ACOLYTE = {
    creatureName: "Acolyte",
    name: "Sister Alana",
    description: "A humble servant of the gods, Sister Alana tends to the spiritual and physical needs of her community, offering blessings and healing to those in need.",
    memories: [],
    info: "An acolyte is a minor religious figure who serves in temples and shrines, offering prayers, healing, and guidance to the faithful.",
    hp: 4,
    maxhp: 4,
    ac: 9,
    size: "medium",
    stats: {
        str: 10,
        dex: 10,
        con: 10,
        wis: 14,
        cha: 11,
    },
    skills: ["Medicine", "Religion"],
    senses: [],
    languages: ["Common"],
    cr: 0.25,
    actions: [
        {
            name: "Club",
            description: "A simple melee attack with a club.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [{ sides: 4 }],
        },
        {
            name: "Sacred Flame",
            description: "A divine flame descends on the target, forcing them to make a Dexterity saving throw or take radiant damage.",
            range: "Near",
            usable: true,
            abilityCheck: {
                stat: ["dex"],
                dc: 11,
            },
            damage: [{ sides: 8 }],
        },
        {
            name: "Bless",
            description: "Grants up to three allies a bonus to attack rolls and saving throws for up to one minute.",
            range: "Close",
            usable: true,
            effects: [
                {
                    name: "Blessed",
                    description: "Affected allies can add 1d4 to their attack rolls and saving throws.",
                    timed: {
                        duration: 60,
                        timeLeft: 60,
                    },
                },
            ],
        },
        {
            name: "Cure Wounds",
            description: "Heals a creature's wounds with divine energy.",
            range: "Close",
            usable: true,
            damage: [-{ sides: 8 }],
        },
        {
            name: "Sanctuary",
            description: "Protects a creature from attacks; attackers must make a Wisdom saving throw to target them.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["wis"],
                dc: 11,
            },
            selfConditions: [
                {
                    name: "Sanctuary",
                    description: "Protected from attacks unless the attacker succeeds on a Wisdom saving throw.",
                    timed: {
                        duration: 60,
                        timeLeft: 60,
                    },
                },
            ],
        },
    ],
    conditions: [],
};
const CAT = {
    creatureName: "Cat",
    name: "Unnamed Cat",
    description: "This agile feline roams the wilderness with grace, always prepared to pounce on unsuspecting prey.",
    memories: [],
    info: "Cats are nimble predators known for their excellent hunting skills and ability to climb and maneuver through various environments effortlessly.",
    hp: 1,
    maxhp: 1,
    ac: 11,
    size: "small",
    stats: {
        str: 3,
        dex: 15,
        con: 10,
        wis: 12,
        cha: 7,
    },
    skills: ["perception", "stealth"],
    senses: ["darkvision 60 ft."],
    languages: [],
    cr: 0,
    actions: [
        {
            name: "Scratch",
            description: "The cat makes a swift attack with its claws.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["dex"],
                dc: "AC",
            },
            damage: [{ sides: 4 }],
        },
    ],
    conditions: [
        {
            name: "Jumper",
            description: "The cat's jump distance is determined using its Dexterity rather than its Strength.",
        },
    ],
};
const CULTIST = {
    creatureName: "Cultist",
    name: "Unnamed Cultist",
    description: "A fanatical follower of a dark deity, often clad in dark robes and wielding a sickle imbued with dark magic.",
    memories: [],
    info: "Cultists are fanatical adherents of sinister powers, often encountered as minions in dark rituals or forbidden ceremonies.",
    hp: 4,
    maxhp: 4,
    ac: 11,
    size: "medium",
    stats: {
        str: 11,
        dex: 12,
        con: 10,
        wis: 11,
        cha: 10,
    },
    skills: ["Deception"],
    senses: [],
    languages: ["Common"],
    cr: 0.125,
    actions: [
        {
            name: "Ritual Sickle",
            description: "A melee attack with a dark, ritualistic weapon that deals slashing and necrotic damage.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [
                { sides: 4 },
                { sides: 1 }, // 1 necrotic
            ],
        },
    ],
    conditions: [],
};
const COW = {
    creatureName: "Cow",
    name: "Unnamed Cow",
    description: "A sturdy, large beast with a gentle demeanor, perfect for grazing in grasslands.",
    memories: [],
    info: "This creature is a common type of cattle, often found in grasslands and domesticated for work or meat. Its sturdy build and horns make it capable of defending itself if needed.",
    hp: 7,
    maxhp: 7,
    ac: 9,
    size: "large",
    stats: {
        str: 18,
        dex: 10,
        con: 14,
        wis: 10,
        cha: 4,
    },
    skills: [],
    senses: [],
    languages: [],
    cr: 0.25,
    actions: [
        {
            name: "Gore",
            description: "A powerful thrust with its horns to inflict piercing damage.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [
                { sides: 6 }, // 1d6
            ],
        },
    ],
    conditions: [],
};
const WRAITH = {
    creatureName: "Wraith",
    name: "Nyxiar",
    description: "Nyxiar drifts as a shadowy figure with glowing, hollow eyes that exude malice. Its ephemeral form shifts and swirls like a dark mist, and its mere presence saps the vitality around it.",
    memories: [],
    info: "A wraith is a malevolent spirit, formed from a soul suffused with negative energy that never passed on to an afterlife. These entities loathe the remnants of their past lives and exist solely to extinguish the living. Wraiths are often surrounded by an aura that withers flora, silences fauna, and extinguishes small fires.",
    hp: 34,
    maxhp: 34,
    ac: 12,
    size: "medium",
    stats: {
        str: 6,
        dex: 16,
        con: 16,
        wis: 14,
        cha: 15,
    },
    skills: [],
    senses: ["darkvision 60 ft."],
    languages: ["the languages it knew in life"],
    cr: 5,
    actions: [
        {
            name: "Life Drain",
            description: "Deals necrotic damage and reduces max HP until a long rest if the target fails a Constitution saving throw.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["con"],
                dc: 12,
            },
            damage: [{ sides: 8 }, { sides: 8 }, { sides: 8 }, { sides: 8 }, 3],
            targetedConditions: [
                {
                    name: "Life Drain",
                    description: "Reduces maximum HP equal to damage until long rest.",
                },
            ],
        },
        {
            name: "Create Specter",
            description: "Turns the spirit of a nearby recently dead humanoid into a specter under its control.",
            range: "Close",
            usable: true,
            selfConditions: [],
        },
    ],
    conditions: [
        {
            name: "Incorporeal Movement",
            description: "Can move through creatures and objects, takes force damage if it ends turn inside an object.",
        },
        {
            name: "Sunlight Sensitivity",
            description: "Disadvantage on attack rolls and sight-based Wisdom (Perception) checks in sunlight.",
        },
    ],
};
const WEREWOLF = {
    creatureName: "Werewolf",
    name: "Ulfgar",
    description: "Ulfgar is a fearsome werewolf, his humanoid form showing a hint of his beastly nature with a fiery temper and sharp features. In hybrid form, he becomes a menacing creature with a muscular frame and a wolf's head, capable of unleashing deadly attacks.",
    memories: [],
    info: "Werewolves are savage predators, able to transform between human, wolf, and a terrifying hybrid of both forms. They exhibit heightened senses and often fear the consequences of embracing their curse. Lycanthropes are known for their dangerous and predatory nature, forming packs and hunting under the full moon.",
    hp: 29,
    maxhp: 29,
    ac: 10,
    size: "medium",
    stats: {
        str: 15,
        dex: 13,
        con: 14,
        wis: 11,
        cha: 10,
    },
    skills: ["perception", "stealth"],
    senses: [],
    languages: ["Common"],
    cr: 3,
    actions: [
        {
            name: "Bite",
            description: "A ferocious bite attack.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [{ sides: 8 }],
            targetedConditions: [
                {
                    name: "Cursed",
                    description: "The target must succeed on a Constitution saving throw or be cursed with lycanthropy.",
                    timed: {
                        duration: 3600,
                        timeLeft: 3600,
                    },
                },
            ],
        },
        {
            name: "Claws",
            description: "A swift attack with claws.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [{ sides: 4 }],
        },
        {
            name: "Spear",
            description: "Uses a spear for a piercing attack.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
                dc: "AC",
            },
            damage: [{ sides: 6 }],
            twoHands: true,
        },
    ],
    conditions: [
        {
            name: "Shapechanger",
            description: "The character can transform into various forms.",
        },
    ],
};
exports.CREATURES = [
    exports.GOBLIN,
    GOBLIN_BOSS,
    SKELETON,
    WRAITH,
    ORC,
    WEREWOLF,
    CAT,
    COW,
    exports.COMMONER,
    exports.GUARD,
    KNIGHT,
    ACOLYTE,
    CULTIST,
    VETERAN,
];
function resolveFullCreature(partialCreature) {
    const fullData = exports.CREATURES.find((creature) => creature.creatureName === partialCreature.creatureName);
    if (!fullData) {
        console.error(`Stat block not found for creature: ${partialCreature.creatureName}. Using 'Commoner' stat block as fallback.`);
    }
    const resolvedData = fullData || exports.COMMONER;
    return Object.assign(Object.assign(Object.assign({}, resolvedData), partialCreature), { stats: Object.assign(Object.assign({}, resolvedData.stats), partialCreature.stats), actions: partialCreature.actions || resolvedData.actions || [], conditions: partialCreature.conditions || resolvedData.conditions || [], skills: partialCreature.skills || resolvedData.skills || [], senses: partialCreature.senses || resolvedData.senses || [], languages: partialCreature.languages || resolvedData.languages || [] });
}
exports.resolveFullCreature = resolveFullCreature;
