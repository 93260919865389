import {
  Action,
  Character,
  Condition,
  Effect,
  isPresent,
  Metadata,
  RollInfo,
  Room,
  Stat,
} from "@ai-dm/utils";
import { World } from "@ai-dm/utils";
import { Armor, Item, Weapon } from "@ai-dm/utils";
import { DELPHIAN_TOMB } from "../data/dungeon";
import { TerminalEntryProps } from "../components/terminal-entry/TerminalEntry";

export interface MessageStringParam {
  content: string;
  role: "user" | "assistant";
}

const convertToMessageParam = (
  entry: TerminalEntryProps
): MessageStringParam => ({
  content: entry.content,
  role: entry.role,
});

export const sendGameMessage = (
  entries: TerminalEntryProps[],
  metadata: Metadata,
  socket: WebSocket | null,
  maxChatLength: number = 30
) => {
  if (!socket) return;

  const filteredEntries = [...entries];
  if (
    filteredEntries.length > 0 &&
    filteredEntries[filteredEntries.length - 1].role === "assistant"
  ) {
    filteredEntries.pop();
  }

  const recentMessages = filteredEntries
    .slice(-maxChatLength)
    .map(convertToMessageParam);

  const websocketMessage = {
    messages: recentMessages,
    metadata: metadata,
  };

  console.log("WebSocket message sent:", websocketMessage);
  socket.send(JSON.stringify(websocketMessage));
};

export const handleUserInputForGame = (
  userInput: string,
  entries: any[],
  character: Character,
  inventory: (Item | Weapon | Armor)[],
  setEntries: (entries: any[]) => void,
  setIsStreaming: (streaming: boolean) => void,
  socket: WebSocket | null,
  MAX_CHAT_LENGTH: number,
  isPresent: (value: any) => boolean
) => {
  const newEntries = [
    ...entries,
    { role: "user", content: userInput },
    { role: "assistant", content: "" },
  ];
  setEntries(newEntries);
  setIsStreaming(true);
  console.log("User message sent:", userInput);
  const messageHistory = [...entries, { role: "user", content: userInput }]
    .slice(-MAX_CHAT_LENGTH)
    .map(({ role, content }) => ({ role, content }));

  const websocketMessage = {
    messages: messageHistory,
    metadata: {
      character: character,
      inventory: inventory,
    },
  };
  if (isPresent(socket)) {
    console.log("WebSocket message sent:", websocketMessage);
    socket?.send(JSON.stringify(websocketMessage));
  }
};

export const initializeWorld = (): World => ({
  name: "",
  currentPOI: DELPHIAN_TOMB,
  currentRoom: "Tomb Entrance",
  currentHex: { x: 0, y: 0 },
});

interface RollResult {
  results: { value: number; type: string }[];
  totalRoll: number;
  rollOutcome: string;
  rollContent: string;
}

interface RollResult {
  results: { value: number; type: string }[];
  totalRoll: number;
  rollOutcome: string;
  rollContent: string;
}

export const processRoll = (
  rollInfo: RollInfo,
  characterStat: number,
  stat: Stat
): RollResult => {
  const dc = rollInfo.dc;

  // Add character stat if it's not zero
  if (characterStat !== 0) {
    rollInfo.roll.push(characterStat);
  }

  const results: { value: number; type: string }[] = [];
  let totalRoll = 0;
  let rollOutcome = "succeeding";

  rollInfo.roll.forEach((item) => {
    if (typeof item === "number") {
      results.push({ value: item, type: "constant" });
      totalRoll += item;
    } else if (item && typeof item === "object" && "sides" in item) {
      const roll = Math.floor(Math.random() * item.sides) + 1;
      results.push({ value: roll, type: `d${item.sides}` });
      totalRoll += roll;
    } else {
      console.error("Unexpected roll item:", item);
    }
  });

  const d20Result = results.find((r) => r.type === "d20")?.value;
  const success = totalRoll >= dc;
  rollOutcome = success ? "succeeding" : "failing";

  if (d20Result === 20) {
    rollOutcome = "critically succeeding";
  } else if (d20Result === 1) {
    rollOutcome = "critically failing";
  }

  const rollContent = `I am rolling a DC ${dc} ${stat.toUpperCase()} check. I rolled a ${totalRoll}, ${rollOutcome}.`;

  return {
    results,
    totalRoll,
    rollOutcome,
    rollContent,
  };
};

export function applyActionResult(
  action: Action,
  success: boolean,
  totalDamage: number,
  targetCreatureNames: string[],
  character: Character,
  inventory: Item[],
  world: World
): {
  newCharacter: Character;
  newInventory: Item[];
  newWorld: World;
  dmActionText: string[]; // lines for %DM_ACTION_START% or any summary
} {
  const newChar = { ...character };
  const newInv = [...inventory];
  const newW = { ...world };

  // We'll store lines like
  // [ "Adrian slashes at Goblin A for 8 damage", "Goblin A is killed" ]
  // so we can wrap them in DM_ACTION_START/END blocks later
  const resultLines: string[] = [];

  if (!success && action.abilityCheck) {
    // Action fails. Possibly partial effect or no effect.
    resultLines.push(`The action, <strong>${action.name}</strong>, fails.`);
    return {
      newCharacter: newChar,
      newInventory: newInv,
      newWorld: newW,
      dmActionText: resultLines,
    };
  }

  // If success, apply damage if present
  if (
    isPresent(newW.currentPOI?.rooms) &&
    isPresent(action.damage) &&
    totalDamage !== 0
  ) {
    // negative totalDamage is healing
    // We loop over target creatures in the current room
    // or possibly you only have 1 target
    const currentRoom = newW.currentPOI?.rooms.find(
      (r) => r.name === newW.currentRoom
    );
    if (currentRoom) {
      const updatedCreatures = currentRoom.creatures.map((creature) => {
        if (targetCreatureNames.includes(creature.name)) {
          // This creature is targeted
          let finalHp = creature.hp - totalDamage;
          // If totalDamage is negative => healing
          // so finalHp = hp - (-4) => hp + 4
          // but we also clamp at creature.maxhp
          if (totalDamage < 0) {
            finalHp = Math.min(creature.maxhp, creature.hp - totalDamage);
          }
          resultLines.push(
            `${character.name} deals ${totalDamage} to ${creature.name} (HP: ${creature.hp} -> ${finalHp})`
          );
          if (finalHp <= 0) {
            finalHp = 0;
            resultLines.push(
              `${creature.creatureName} ${creature.name} is defeated!`
            );
          }

          return { ...creature, hp: finalHp };
        }
        return creature;
      });
      currentRoom.creatures = updatedCreatures;
    }
  }

  // If success, apply conditions to target
  if (
    isPresent(newW.currentPOI?.rooms) &&
    action.targetedConditions &&
    action.targetedConditions.length > 0
  ) {
    // e.g. applying "Poisoned"
    const currentRoom = newW.currentPOI?.rooms.find(
      (r) => r.name === newW.currentRoom
    );
    if (currentRoom) {
      currentRoom.creatures = currentRoom.creatures.map((creature) => {
        if (targetCreatureNames.includes(creature.name)) {
          const updatedConditions = [
            ...creature.conditions,
            ...action.targetedConditions!,
          ];
          resultLines.push(
            `${creature.name} gains conditions: ${action.targetedConditions
              ?.map((c) => c.name)
              .join(", ")}`
          );
          return { ...creature, conditions: updatedConditions };
        }
        return creature;
      });
    }
  }

  // If success, apply conditions to self
  if (action.selfConditions && action.selfConditions.length > 0) {
    const updatedConditions = [...newChar.conditions, ...action.selfConditions];
    resultLines.push(
      `${character.name} gains self-conditions: ${action.selfConditions
        .map((c) => c.name)
        .join(", ")}`
    );
    newChar.conditions = updatedConditions;
  }

  // If success, apply effects to the room
  if (
    isPresent(newW.currentPOI?.rooms) &&
    action.effects &&
    action.effects.length > 0
  ) {
    const currentRoom = newW.currentPOI?.rooms.find(
      (r) => r.name === newW.currentRoom
    );
    if (currentRoom) {
      const updatedEffects = [...currentRoom.effects, ...action.effects];
      currentRoom.effects = updatedEffects;
      resultLines.push(
        `The room gains the following effects: ${action.effects
          .map((e) => e.name)
          .join(", ")}`
      );
    }
  }

  // Possibly consume uses
  // if (action.usesPerRest) ...

  return {
    newCharacter: newChar,
    newInventory: newInv,
    newWorld: newW,
    dmActionText: resultLines,
  };
}
