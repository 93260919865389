"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.describeCondition = exports.describeAction = exports.describeAbility = exports.isValidStat = void 0;
const general_utils_1 = require("./general-utils");
const isValidStat = (stat) => {
    return ["str", "dex", "cha", "con", "wis"].includes(stat);
};
exports.isValidStat = isValidStat;
function describeAbility(ability, lessDetail = false) {
    if (lessDetail) {
        return `${ability.name}: ${ability.description}`;
    }
    let description = `${ability.name}: ${ability.description}`;
    description += `\nType: ${ability.type}`;
    if (ability.cost) {
        description += `\nCost: ${ability.cost}`;
    }
    if (ability.prereq) {
        description += `\nPrerequisite: ${ability.prereq}`;
    }
    if (ability.timed) {
        description += `\nDuration: ${ability.timed.duration}s, Time Left: ${ability.timed.timeLeft}s`;
    }
    if (ability.skills && ability.skills.length > 0) {
        description += `\nSkills: ${ability.skills.join(", ")}`;
    }
    if (ability.languages && ability.languages.length > 0) {
        description += `\nLanguages: ${ability.languages.join(", ")}`;
    }
    if (ability.actions && ability.actions.length > 0) {
        description += `\nActions:`;
        for (const action of ability.actions) {
            description += `\n  ${describeAction(action)}`;
        }
    }
    if (ability.conditions && ability.conditions.length > 0) {
        description += `\nConditions:`;
        for (const condition of ability.conditions) {
            description += `\n  ${describeCondition(condition)}`;
        }
    }
    return description;
}
exports.describeAbility = describeAbility;
function describeAction(action) {
    let description = `${action.name}: ${action.description}\nRange: ${action.range}`;
    if (action.abilityCheck) {
        description += `\nAbility Check: ${action.abilityCheck.stat.join(", ")} check`;
        if ((0, general_utils_1.isPresent)(action.abilityCheck.dc)) {
            description += ` with DC ${action.abilityCheck.dc === "AC"
                ? `based on targets AC`
                : action.abilityCheck.dc}`;
        }
    }
    if (action.damage) {
        description += `\nDamage: ${JSON.stringify(action.damage)}`;
    }
    if (action.effects && action.effects.length > 0) {
        description += `\nEffects: ${action.effects
            .map((effect) => effect.name)
            .join(", ")}`;
    }
    if (action.twoHands) {
        description += `\nRequires Two Hands`;
    }
    if (action.usesPerRest) {
        description += `\nUses Per Rest: ${action.usesPerRest.used}/${action.usesPerRest.total}`;
    }
    if (action.usesEver) {
        description += `\nUses Ever: ${action.usesEver.used}/${action.usesEver.total}`;
    }
    return description;
}
exports.describeAction = describeAction;
function describeCondition(condition) {
    let description = `${condition.name}: ${condition.description}`;
    if (condition.timed) {
        description += `\nDuration: ${condition.timed.duration}s, Time Left: ${condition.timed.timeLeft}s`;
    }
    return description;
}
exports.describeCondition = describeCondition;
