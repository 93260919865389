import { Armor, formatRoll, isArmor, Item, Weapon } from "@ai-dm/utils";
import React, { useState } from "react";
import { CheckSquare, Square } from "lucide-react";
import { isPresent } from "../../../utils/utility";

interface InventoryProps {
  inventory: Item[];
  onEquipToggle: (item: Item) => void;
}

export const Inventory: React.FC<InventoryProps> = ({
  inventory,
  onEquipToggle,
}) => {
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  const [expandedSubItem, setExpandedSubItem] = useState<string | null>(null);
  const [expandedSubSubItem, setExpandedSubSubItem] = useState<string | null>(
    null
  );

  const toggleItem = (itemName: string) => {
    if (expandedItem === itemName) {
      setExpandedItem(null);
    } else {
      setExpandedItem(itemName);
    }
  };

  const toggleSubItem = (subItemName: string) => {
    if (expandedSubItem === subItemName) {
      setExpandedSubItem(null);
    } else {
      setExpandedSubItem(subItemName);
    }
  };

  const toggleSubSubItem = (subSubItemName: string) => {
    if (expandedSubSubItem === subSubItemName) {
      setExpandedSubSubItem(null);
    } else {
      setExpandedSubSubItem(subSubItemName);
    }
  };

  const handleEquipClick = (item: Item, e: React.MouseEvent) => {
    e.stopPropagation();

    onEquipToggle(item);
  };

  return (
    <div className="side-panel">
      <h2>Inventory</h2>
      <p>
        <strong>Slots: </strong>
        {inventory.length}/{20}
      </p>
      <ul className="space-y-2">
        {inventory.map((item) => (
          <li
            key={item.name}
            className="border border-gray-700 rounded bg-secondary"
          >
            <div className="flex flex-col">
              <button
                className="flex justify-between items-center w-full p-2  transition-colors text-left"
                onClick={() => toggleItem(item.name)}
              >
                <span className="flex items-center gap-2">
                  {item.equipable && (
                    <button
                      onClick={(e) => handleEquipClick(item, e)}
                      className="hover:text-gray-400 transition-colors"
                      title={item.equipped ? "Unequip" : "Equip"}
                    >
                      {item.equipped ? (
                        <CheckSquare className="h-5 w-5" />
                      ) : (
                        <Square className="h-5 w-5" />
                      )}
                    </button>
                  )}
                  {item.name}
                </span>
              </button>

              {expandedItem === item.name && (
                <div className="px-2 pb-2">
                  <em className="block text-gray-400 text-base leading-tight">
                    {item.description}
                  </em>
                  <div className="mt-2 space-y-0.5 text-base">
                    <p>
                      <b>Type:</b> {item.type}
                    </p>
                    <p>
                      <b>Slots:</b> {item.slots}
                    </p>
                    <p>
                      <b>Value:</b> {item.value}
                    </p>
                    {isPresent(item.actions) && item.actions.length >= 1 && (
                      <div>
                        <b>Actions:</b>
                        <ul className="space-y-2">
                          {item.actions.map((action, index) => (
                            <li
                              key={index}
                              className="border border-gray-700 rounded bg-secondary"
                            >
                              <button
                                className="flex justify-between items-center w-full p-2 text-left transition-colors"
                                onClick={() => toggleSubItem(action.name)}
                              >
                                <span className="flex items-center gap-2">
                                  {action.name}
                                </span>
                              </button>

                              {expandedSubItem === action.name && (
                                <div className="px-2 pb-2">
                                  <em className="block text-gray-400 text-base leading-tight">
                                    {action.description}
                                  </em>
                                  <div className="mt-2 space-y-0.5 text-base">
                                    <p>
                                      <b>Range:</b> {action.range}
                                    </p>
                                    {action.abilityCheck &&
                                      action.abilityCheck.stat.length > 0 && (
                                        <p>
                                          <b>Ability Check:</b>{" "}
                                          {action.abilityCheck.stat.join(", ")}
                                        </p>
                                      )}
                                    {action.damage && (
                                      <p>
                                        <b>Damage:</b>{" "}
                                        {formatRoll(action.damage)}
                                      </p>
                                    )}
                                    {action.effects && (
                                      <p>
                                        <b>Effects:</b>
                                        {action.effects.map((effect, index) => (
                                          <li
                                            key={index}
                                            className="border border-gray-700 rounded bg-secondary"
                                          >
                                            <button
                                              className="flex justify-between items-center w-full p-2 text-left transition-colors"
                                              onClick={() =>
                                                toggleSubSubItem(effect.name)
                                              }
                                            >
                                              <span className="flex items-center gap-2">
                                                {effect.name}
                                              </span>
                                            </button>

                                            {expandedSubSubItem ===
                                              effect.name && (
                                              <div className="px-2 pb-2">
                                                <em className="block text-gray-400 text-base leading-tight">
                                                  {effect.description}
                                                </em>
                                                <div className="mt-2 space-y-0.5 text-base">
                                                  {effect.timed && (
                                                    <p>
                                                      <b>Duration:</b>{" "}
                                                      {effect.timed.timeLeft}/
                                                      {effect.timed.duration}
                                                    </p>
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </li>
                                        ))}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    {isPresent(item.conditions) &&
                      item.conditions.conditionList.length >= 1 && (
                        <div>
                          <b>Conditions:</b>
                          <ul className="space-y-2">
                            {item.conditions.conditionList.map(
                              (condition, index) => (
                                <li
                                  key={index}
                                  className="border border-gray-700 rounded bg-secondary"
                                >
                                  <button
                                    className="flex justify-between items-center w-full p-2 text-left transition-colors"
                                    onClick={() =>
                                      toggleSubItem(condition.name)
                                    }
                                  >
                                    <span className="flex items-center gap-2">
                                      {condition.name}
                                    </span>
                                  </button>

                                  {expandedSubItem === condition.name && (
                                    <div className="px-2 pb-2">
                                      <em className="block text-gray-400 text-base leading-tight">
                                        {condition.description}
                                      </em>
                                      <div className="mt-2 space-y-0.5 text-base">
                                        {condition.timed && (
                                          <p>
                                            <b>Duration:</b>{" "}
                                            {condition.timed.timeLeft}
                                            {"/"}
                                            {condition.timed.duration}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      )}
                  </div>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
