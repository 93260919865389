"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.describeRoom = exports.describeEffect = exports.describeCreature = void 0;
const character_1 = require("./character");
const general_utils_1 = require("./general-utils");
const inventory_1 = require("./inventory");
function describeCreature(creature, lessDetail = false) {
    if (lessDetail) {
        return `${creature.name} (${creature.creatureName}, CR ${creature.cr}, AC: ${creature.ac}): ${creature.description} General Info: ${creature.info}`;
    }
    let description = `${creature.name} (${creature.creatureName}, CR ${creature.cr}): ${creature.description}`;
    description += `\nHP: ${creature.hp}/${creature.maxhp}`;
    description += `\nAC: ${creature.ac}`;
    description += `\nSize: ${creature.size}`;
    // Stats
    description += `\nStats:`;
    for (const [stat, value] of Object.entries(creature.stats)) {
        description += `\n  ${stat}: ${value}`;
    }
    // Skills, Senses, and Languages
    description += `\nSkills: ${creature.skills.join(", ")}`;
    description += `\nSenses: ${creature.senses.join(", ")}`;
    description += `\nLanguages: ${creature.languages.join(", ")}`;
    // Actions
    if (creature.actions.length > 0) {
        description += `\nActions:`;
        for (const action of creature.actions) {
            description += `\n  ${(0, character_1.describeAction)(action)}`;
        }
    }
    // Conditions
    if (creature.conditions.length > 0) {
        description += `\nConditions:`;
        for (const condition of creature.conditions) {
            description += `\n  ${(0, character_1.describeCondition)(condition)}`;
        }
    }
    return description;
}
exports.describeCreature = describeCreature;
function describeEffect(effect) {
    let description = `${effect.name}: ${effect.description}`;
    if ((0, general_utils_1.isPresent)(effect.timed)) {
        description += ` Duration: ${effect.timed.timeLeft} seconds remaining out of ${effect.timed.duration}`;
    }
    return description;
}
exports.describeEffect = describeEffect;
function describeRoom(room, lessDetail = false) {
    let description = `Room Name: ${room.name}\nDescription: ${room.description}`;
    description += `\nExits:`;
    for (const exit of room.exits) {
        description += `\n  - To: ${exit.toRoom}, Description: ${exit.description}`;
    }
    if (room.creatures.length > 0) {
        description += `\nCreatures in Room:`;
        for (const creature of room.creatures) {
            description += lessDetail
                ? `\n  - ${describeCreature(creature, true)}`
                : `\n  - ${describeCreature(creature, false)}`;
        }
    }
    else {
        description += `\nNo important creatures in this room.`;
    }
    if (room.items.length > 0) {
        description += `\nItems in Room:`;
        for (const item of room.items) {
            description += lessDetail
                ? `\n  - ${(0, inventory_1.describeItem)(item, true)}`
                : `\n  - ${(0, inventory_1.describeItem)(item, false)}`;
        }
    }
    if (room.effects.length > 0) {
        description += `\nActive Effects:`;
        for (const effect of room.effects) {
            description += `\n  - ${describeEffect(effect)}`;
        }
    }
    return description;
}
exports.describeRoom = describeRoom;
