"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHIELD = exports.CHAINMAIL = void 0;
exports.CHAINMAIL = {
    name: "Chainmail Shirt",
    type: "Armor",
    description: "Sturdy rings of metal that jingle with each movement, offering solid protection without weighing you down too much.",
    ac: 3,
    modifier: "Dex/2",
    location: "Chest",
    conditions: {
        mustBeEquipped: true,
        conditionList: [
            {
                name: "Loud Movement",
                description: "The loud ring of chainmail links imposes disadvantage on all stealth checks.",
            },
            {
                name: "Heavy",
                description: "The weight and bulk of the Chainmail Shirt imposes disadvantage on all attempts to swim.",
            },
        ],
    },
    equipable: true,
    equipped: false,
    slots: 2,
    value: 600,
    actions: [],
};
exports.SHIELD = {
    name: "Shield",
    type: "Armor",
    description: "A simple but reliable shield, bearing the marks of countless parried blows, ready to defend against even the fiercest attacks.",
    ac: 2,
    modifier: "None",
    location: "Hand",
    equipable: true,
    equipped: true,
    slots: 1,
    value: 100,
    actions: [
        {
            name: "Shield Bash",
            description: "A powerful thrust with your shield that can knock enemies back.",
            range: "Close",
            usable: true,
            abilityCheck: {
                stat: ["str"],
            },
            damage: [{ sides: 4 }],
        },
    ],
};
