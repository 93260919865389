"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.describeItem = exports.isArmor = exports.isWeapon = void 0;
const isWeapon = (item) => {
    return item.type === "Weapon";
};
exports.isWeapon = isWeapon;
function isArmor(item) {
    return item.type === "Armor";
}
exports.isArmor = isArmor;
function describeItem(item, lessDetail = false) {
    if (lessDetail) {
        return `${item.name}: ${item.description}`;
    }
    let description = `${item.name}: ${item.description}`;
    if (item.equipable) {
        description += `\nEquipable: ${item.equipable ? "Yes" : "No"}`;
        description += `\nEquipped: ${item.equipped ? "Yes" : "No"}`;
    }
    description += `\nType: ${item.type}`;
    description += `\nValue: ${item.value}`;
    description += `\nSlots: ${item.slots}`;
    if ((0, exports.isWeapon)(item)) {
        description += `\nLocation: ${item.location}`;
    }
    else if (isArmor(item)) {
        description += `\nLocation: ${item.location}`;
        description += `\nAC: ${item.ac}`;
        description += `\nModifier: ${item.modifier}`;
    }
    if (item.actions && item.actions.length > 0) {
        description += `\nActions:`;
        for (const action of item.actions) {
            description += `\n  - ${action.name}: ${action.description}`;
            description += `\n    Range: ${action.range}`;
            if (action.abilityCheck) {
                description += `\n    Ability Check: ${action.abilityCheck.stat.join(", ")}`;
            }
            if (action.damage) {
                description += `\n    Damage: ${JSON.stringify(action.damage)}`;
            }
            if (action.effects && action.effects.length > 0) {
                description += `\n    Effects: ${action.effects
                    .map((effect) => effect.name)
                    .join(", ")}`;
            }
        }
    }
    if (item.conditions && item.conditions.conditionList.length > 0) {
        description += `\nConditions:`;
        for (const condition of item.conditions.conditionList) {
            description += `\n  - ${condition.name}: ${condition.description}`;
            if (condition.timed) {
                description += ` (Duration: ${condition.timed.duration}s, Time Left: ${condition.timed.timeLeft}s)`;
            }
        }
    }
    return description;
}
exports.describeItem = describeItem;
