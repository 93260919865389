"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LONGSWORD = void 0;
exports.LONGSWORD = {
    name: "Longsword",
    type: "Weapon",
    description: "A well-worn blade with a keen edge, perfect for dealing heavy blows in close combat.",
    equipped: false,
    equipable: true,
    location: "Hand",
    slots: 1,
    value: 90,
    actions: [
        {
            name: "Longsword Strike",
            description: "A powerful slash or thrust with your longsword.",
            usable: true,
            range: "Close",
            abilityCheck: {
                stat: ["str"],
            },
            damage: [{ sides: 8 }, { sides: 20, disadvantage: true }, -1],
            twoHands: false,
        },
    ],
};
