"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_INVENTORY = exports.WAND = exports.BACKPACK = void 0;
const armor_1 = require("./armor");
const weapons_1 = require("./weapons");
exports.BACKPACK = {
    name: "Backpack",
    type: "Other",
    equipable: true,
    equipped: false,
    description: "A sturdy pack with plenty of room for all your adventuring gear.",
    slots: 0,
    value: 20,
};
exports.WAND = {
    name: "Amulet of Transformation",
    type: "Other",
    equipable: true,
    equipped: false,
    description: "Amulet capable of transforming any target as the wielder wishes.",
    actions: [
        {
            name: "Transform",
            description: "Subtly transforms the target into a creature, object, or form of the wielder's choice, allowing changes to mind or body.",
            range: "Far",
            usable: true,
            targetedConditions: [
                {
                    name: "Transformed",
                    description: "The target is transformed into the chosen form for the duration of the effect.",
                    timed: {
                        duration: 600,
                        timeLeft: 600,
                    },
                },
            ],
        },
    ],
    slots: 1,
    value: 200,
};
exports.DEFAULT_INVENTORY = [
    Object.assign(Object.assign({}, weapons_1.LONGSWORD), { equipped: true }),
    Object.assign(Object.assign({}, armor_1.CHAINMAIL), { equipped: true }),
    Object.assign(Object.assign({}, armor_1.SHIELD), { equipped: true }),
    Object.assign(Object.assign({}, exports.BACKPACK), { equipped: true }),
    // { ...WAND, equipped: true },
];
