import { formatRoll, Roll, Stat } from "@ai-dm/utils";
import { useState } from "react";

// DiceRoll.tsx
export interface DiceRollProps {
  type: Stat;
  roll: Roll;
  dc: number;
  results: { value: number; type: string }[]; // Pre-computed results
  totalRoll: number; // Pre-computed total
  onRollReveal: (description: string) => void; // Renamed to better reflect purpose
}

export const DiceRoll: React.FC<DiceRollProps> = ({
  type,
  roll,
  dc,
  results,
  totalRoll,
  onRollReveal,
}) => {
  const [revealed, setRevealed] = useState(false);

  const formatRollDescription = (
    results: { value: number; type: string }[],
    total: number,
    dc: number
  ): string => {
    const parts = results.map((result) => {
      if (result.type === "constant") {
        return result.value >= 0 ? `+${result.value}` : result.value.toString();
      }
      return `${result.value}[${result.type}]`;
    });

    const d20Result = results.find((r) => r.type === "d20")?.value;
    const success = total >= dc;
    let successText = "";
    if (d20Result === 20) {
      successText = "Critical Success!";
    } else if (d20Result === 1) {
      successText = "Critical Failure!";
    } else if (success) {
      successText = "Success!";
    } else {
      successText = "Failure.";
    }

    return `${parts
      .join("+")
      .replace(/\+-/g, "-")} = ${total} vs DC ${dc} - ${successText}`;
  };

  const handleReveal = () => {
    setRevealed(true);
    const description = formatRollDescription(results, totalRoll, dc);
    onRollReveal(description);
  };

  const getRollString = (): string => {
    if (!revealed) {
      return formatRoll(roll);
    }
    return formatRollDescription(results, totalRoll, dc);
  };

  return (
    <div className="flex justify-between items-center p-2 bg-secondary rounded">
      <div className="flex flex-col flex-grow">
        <div className="text-lg">
          &gt; DC {dc} {type.toString().toUpperCase()} Check
        </div>
        <div className={`text-2xl font-bold`}>{getRollString()}</div>
      </div>
      <button
        className={`button ${revealed ? `cursor-not-allowed` : ``}`}
        onClick={handleReveal}
        disabled={revealed}
      >
        {revealed ? "Rolled" : "Roll"}
      </button>
    </div>
  );
};
