import { Creature, isPresent, POI, Room, World } from "@ai-dm/utils";
import * as d3 from "d3";
import React, { useCallback, useEffect, useState } from "react";

interface POITabProps {
  world: World;
  updateWorld: (key: keyof World, value: any) => void;
}

export const POITab: React.FC<POITabProps> = ({ world, updateWorld }) => {
  const { currentPOI, currentHex, currentRoom } = world;
  const [poiType, setPOIType] = useState<string>("village");
  const [hoveredRoom, setHoveredRoom] = useState<Room | null>(null);
  const [hoverPosition, setHoverPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  // Generate POI graph
  const generatePOIGraph = useCallback(async () => {
    try {
      console.log("Generating");
      // Fetch low-detail POI
      const lowDetailResponse = await fetch(
        "http://localhost:3000/generate-poi-low-detail",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ type: poiType }),
        }
      );
      if (!lowDetailResponse.ok) {
        throw new Error("Failed to fetch low-detail POI.");
      }
      const lowDetail = await lowDetailResponse.json();
      console.log("Low Detail:", JSON.stringify(lowDetail, null, 2));

      // Fetch high-detail POI
      const highDetailResponse = await fetch(
        "http://localhost:3000/generate-poi-high-detail",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ lowDetail }),
        }
      );
      if (!highDetailResponse.ok) {
        throw new Error("Failed to fetch high-detail POI.");
      }
      const highDetail: POI = await highDetailResponse.json();
      console.log("High Detail:", JSON.stringify(highDetail, null, 2));
      updateWorld(
        "currentRoom",
        highDetail.rooms?.find((room) => room.entrance) || highDetail.rooms?.[0]
      );
      updateWorld("currentPOI", highDetail);
    } catch (error) {
      console.error("Error generating POI graph:", error);
    }
  }, [poiType]);

  // Render Graph using D3
  const renderGraph = useCallback(() => {
    if (!currentPOI || !currentPOI.rooms) return;

    const width = 500;
    const height = 800;
    const svg = d3
      .select("#poi-graph")
      .attr("viewBox", `0 0 ${width} ${height}`);

    // Clear previous graph
    svg.selectAll("*").remove();

    // Prepare nodes and links
    const nodes = currentPOI.rooms.map((room: any) => ({
      id: room.name, // Use room name as ID
      name: room.name,
      x: width / 2,
      y: height / 2,
    }));

    const links: { source: string; target: string }[] = currentPOI.rooms
      .flatMap((room: Room) =>
        room.exits.map((exit) => {
          const sourceNode = nodes.find((n) => n.id === room.name);
          const targetNode = nodes.find((n) => n.id === exit.toRoom);

          if (sourceNode && targetNode) {
            return {
              source: sourceNode.id,
              target: targetNode.id,
            };
          }
          return null;
        })
      )
      .filter(
        (link): link is { source: string; target: string } => link !== null
      );

    // Simulate positions
    const simulation = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3
          .forceLink(links)
          .id((d: any) => d.id) // Match nodes by ID
          .distance(200)
      )
      .force("charge", d3.forceManyBody().strength(-500))
      .force("center", d3.forceCenter(width / 2, height / 2));

    simulation.on("tick", () => {
      // Render links
      svg
        .selectAll(".link")
        .data(links)
        .join("line")
        .attr("class", "link")
        .attr("stroke", "black")
        .attr("stroke-width", 2)
        .attr("x1", (d: any) => (d.source as any).x)
        .attr("y1", (d: any) => (d.source as any).y)
        .attr("x2", (d: any) => (d.target as any).x)
        .attr("y2", (d: any) => (d.target as any).y);

      // Render nodes
      svg
        .selectAll(".node")
        .data(nodes)
        .join("circle")
        .attr("class", "node")
        .attr("r", 40)
        .attr("fill", (d: any) => {
          if (isPresent(currentPOI.rooms)) {
            const room = currentPOI.rooms.find((room) => room.name === d.name);
            return room?.name === currentRoom
              ? "#FF0000"
              : room?.entrance
              ? "#004080"
              : "#007BFF";
          } else {
            return "#007BFF";
          }
          // Darker blue for entrance, default blue for others
        })
        .attr("cx", (d: any) => d.x)
        .attr("cy", (d: any) => d.y)
        .on("mouseenter", (event: MouseEvent, d: any) => {
          if (isPresent(currentPOI.rooms)) {
            const room = currentPOI.rooms.find((room) => room.name === d.name);
            setHoveredRoom(room || null);
          }
          setHoverPosition({ x: event.clientX, y: event.clientY });
        })
        .on("mouseleave", () => setHoveredRoom(null));

      // Render labels
      svg
        .selectAll(".label")
        .data(nodes)
        .join("text")
        .attr("class", "label")
        .attr("x", (d: any) => d.x)
        .attr("y", (d: any) => d.y - 30)
        .attr("text-anchor", "middle")
        .text((d: any) => d.name)
        .attr("fill", "#333")
        .style("font-size", "40px");
    });
  }, [currentPOI]);

  useEffect(() => {
    renderGraph();
  }, [currentPOI, renderGraph]);

  return (
    <div className="side-panel p-4 text-sm">
      <div>
        <svg id="poi-graph" className="w-full h-[200px] border bg-white"></svg>

        {hoveredRoom && (
          <div
            className="fixed bg-black text-white p-4 rounded"
            style={{
              top: hoverPosition.y + 10,
              left: hoverPosition.x + 10,
            }}
          >
            <strong>{hoveredRoom.name}</strong>
          </div>
        )}
      </div>
      <div className="mb-4">
        <p className="font-semibold">
          Current Hex Coords:{" "}
          <span className="font-normal">
            x: {currentHex.x}, y: {currentHex.y}
          </span>
        </p>
      </div>

      {/* If there is a current POI, render it */}
      {currentPOI && <PoiDetails poi={currentPOI} currentRoom={currentRoom} />}
      <div className="flex gap-4 mb-6">
        <select
          className="border rounded px-4 py-2 text-black"
          value={poiType}
          onChange={(e) => setPOIType(e.target.value)}
        >
          <option value="village">Village</option>
        </select>
        <button className="button" onClick={generatePOIGraph}>
          Generate POI Graph
        </button>
      </div>
    </div>
  );
};

/**
 * POI component that shows the list of rooms (expandable)
 */
const PoiDetails: React.FC<{ poi: POI; currentRoom: string | undefined }> = ({
  poi,
  currentRoom,
}) => {
  return (
    <div className="border border-gray-300 p-2 rounded mb-4">
      <div className="mb-2">
        <h3 className="text-md font-semibold">{poi.name}</h3>
        <p className="text-sm">{poi.description}</p>
      </div>
      {poi.rooms && (
        <div>
          {poi.rooms.map((room, idx) => (
            <RoomDetails key={idx} room={room} currentRoom={currentRoom} />
          ))}
        </div>
      )}
    </div>
  );
};

/**
 * Room component that can be collapsed/expanded
 */
export const RoomDetails: React.FC<{
  room: Room;
  currentRoom: string | undefined;
}> = ({ room, currentRoom }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const borderColor =
    room.name === currentRoom ? "border-white" : "border-gray-600";

  return (
    <div className={`border ${borderColor} p-2 rounded mb-2`}>
      {/* Header (with expand/collapse button) */}
      <div className="flex justify-between items-center cursor-pointer">
        <h4 className="font-semibold">{room.name}</h4>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-gray-600 hover:text-white focus:outline-none"
        >
          {isExpanded ? "-" : "+"}
        </button>
      </div>

      {/* Body only appears if expanded */}
      {isExpanded && (
        <div className="mt-2 text-sm">
          <p className="mb-2">{room.description}</p>
          <div className="mb-2">
            <strong>Exits:</strong>
            <ul className="list-disc list-inside ml-4">
              {room.exits.map((exit, i) => (
                <li key={i}>
                  <strong>To:</strong> {exit.toRoom} – {exit.description}
                </li>
              ))}
            </ul>
          </div>

          {/* Creatures */}
          {room.creatures && (
            <div>
              <strong>Creatures:</strong>
              {room.creatures.length === 0 ? (
                <p className="text-gray-600">No creatures here.</p>
              ) : (
                room.creatures.map((creature, idx) => (
                  <CreatureDetails key={idx} creature={creature} />
                ))
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Creature component that can be collapsed/expanded
 */
export const CreatureDetails: React.FC<{ creature: Creature }> = ({
  creature,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="border border-gray-200 rounded p-2 mt-2">
      <div className="flex justify-between items-center cursor-pointer">
        <span className="font-semibold">
          {creature.name} ({creature.creatureName})
        </span>
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-gray-600 hover:text-white focus:outline-none"
        >
          {isExpanded ? "-" : "+"}
        </button>
      </div>

      {isExpanded && (
        <div className="mt-2 text-sm">
          <p className="italic">{creature.description}</p>

          <div className="mt-2">
            <strong>HP:</strong> {creature.hp}/{creature.maxhp} –{" "}
            <strong>AC:</strong> {creature.ac}
          </div>
          <div>
            <strong>Size:</strong> {creature.size}
          </div>
          <div>
            <strong>Conditions:</strong>{" "}
            {creature.conditions.length
              ? creature.conditions.join(", ")
              : "None"}
          </div>

          {/* Stats */}
          <div className="mt-2">
            <strong>Stats:</strong>
            <ul className="list-disc list-inside ml-4">
              {Object.entries(creature.stats).map(([stat, value]) => (
                <li key={stat}>
                  {stat}: {value}
                </li>
              ))}
            </ul>
          </div>

          {/* Skills */}
          <div className="mt-2">
            <strong>Skills:</strong>{" "}
            {creature.skills.length ? creature.skills.join(", ") : "None"}
          </div>

          {/* Senses */}
          <div>
            <strong>Senses:</strong>{" "}
            {creature.senses.length ? creature.senses.join(", ") : "None"}
          </div>

          {/* Languages */}
          <div>
            <strong>Languages:</strong>{" "}
            {creature.languages.length ? creature.languages.join(", ") : "None"}
          </div>

          {/* CR */}
          <div>
            <strong>CR:</strong> {creature.cr}
          </div>

          {/* Conditions */}
          <div className="mt-2">
            <strong>Conditions:</strong>
            {creature.conditions.length ? (
              <ul className="list-disc list-inside ml-4">
                {creature.conditions.map((condition, i) => (
                  <li key={i}>
                    <strong>{condition.name}:</strong> {condition.description}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">No abilities</p>
            )}
          </div>

          {/* Attacks */}
          <div className="mt-2">
            <strong>Actions:</strong>
            {creature.actions.length ? (
              <ul className="list-disc list-inside ml-4">
                {creature.actions.map((attack, i) => (
                  <li key={i}>
                    <strong>{attack.name}:</strong> {attack.description}{" "}
                    (Damage: {JSON.stringify(attack.damage)})
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">No attacks</p>
            )}
          </div>

          {/* Info */}
          {creature.info && (
            <div className="mt-2">
              <strong>Info:</strong> {creature.info}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
